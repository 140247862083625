import React, { useRef } from "react";
import ContactUs from "../../Components/ContactUs";
import Slider from "react-slick";
import portfolioImage from '../../Media/Images/portfolio.png';
// import PlazaImage from '../../Media/Images/plaza.png';
import palaceImage from '../../Media/Images/palace.png';
import stadium from '../../Media/Images/slider1.png';
// import raliway from '../../Media/Images/railway.png';
// import japantown from '../../Media/Images/japantown.png';
import slidershade from "../../Media/Images/slider-shade.png";
// import japantownImg from "../../Media/Images/slider.png"
import BannerLink from "../../Components/BannerLink";
import ServiceImages from '../../Media/Images/service.png';
import RealEstate from '../../Media/Images/real-estate.png';
import FinanceImages from '../../Media/Images/finance.png';
import { Link, useLocation } from "react-router-dom";
import { RoutesPaths } from '../../Router/constant';


import japantown1 from '../../Media/Images/japantown1.png';
import japantown2 from '../../Media/Images/japantown2.png';
import japantown3 from '../../Media/Images/japantown3.png';
import former from '../../Media/Images/former1.png';
import cityAlameda1 from '../../Media/Images/cityAlameda1.png';
import cityAlameda2 from '../../Media/Images/cityAlameda2.png';
import plaza1 from '../../Media/Images/Plaza1.png';
import plaza2 from '../../Media/Images//Plaza2.png';
import coyote1 from '../../Media/Images/Coyote1.png';
import coyote2 from '../../Media/Images/Coyote2.png';
import coyote3 from '../../Media/Images/Coyote3.png';
import diridon1 from '../../Media/Images/DiridonArea1.png';
import diridon2 from '../../Media/Images//DiridonArea2.png';
import northSan1 from '../../Media/Images/NorthSan1.png';
import northSan2 from '../../Media/Images/NorthSan2.png';
import { useEffect } from "react";

export default function Dashboard() {
  const slider = React.useRef(null);
  const location = useLocation()
  const sectionRef = useRef(null);

  const scrollClick = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.state === 'dashboard') {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location])

  var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: true,
    arrows: false,
    centerPadding: '10px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerPadding: '20px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerPadding: '20px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
        }
      }
    ]
  };

  const sliderCards = [
    {
      img: japantown3,
      title: 'Japantown Corporation yard relocation:',
      description: '600,000 square feet industrial, 5.25 acres',
      titledetail: 'In June 2004, the Mayor and City Council of San Jose took a significant step by directing the executive director of the San Jose Redevelopment Agency t—---o relocate the Japantown Corporation Yard. Tasked with overseeing this challenging endeavor, Bob Staedler was chosen to lead the Redevelopment and City team in developing and executing a relocation plan. Bob Staedler`s approach was rooted in community engagement, collaborating closely with Japantown community leaders through a series of over 100 meetings.',
      titledetailimg: japantown1,
      titledetail1: 'Remarkably, the relocation plan that was ultimately implemented achieved substantial cost savings. While the original city masterplan budgeted $64 million for the relocation, Bob Staedler`s diligent efforts and a streamlined design-build process resulted in a final cost of only $28.8 million for the development of replacement corporation yard space on a 5.25 acre site. The site underwent a remarkable transformation. Shea, a renowned developer, redeveloped the space, resulting in the construction of 520 apartments and 19,000 square feet of retail space. This successful redevelopment not only revitalized the area but also fostered a vibrant and thriving community for residents and visitors alike.',
      titledetailimg1: japantown2,
    },
    {
      img: stadium,
      title: 'Former San Jose Baseball site, now Google Downtown West',
      description: '12 acres',
      titledetail: 'As the Real Estate Manager for the San Jose Redevelopment Agency, Bob Staedler spearheaded the acquisition of a significant 12-acre parcel intended for a potential Athletics ballpark. Demonstrating exceptional strategic acumen, Bob devised a plan to leverage the Agency`s assets, orchestrating their sale to secure the necessary funds for the purchase of the coveted Diridon Area property.While the relocation of the Athletics to San Jose did not materialize, this turn of events proved fortuitous for the future of the Diridon Station Area. In a twist of fate, Major League Baseball`s decision not to proceed with the relocation paved the way for new opportunities to unfold.',
      titledetailimg: former,
      titledetail1: 'In a remarkable development, technology giant Google expressed keen interest in developing a transit-oriented campus in the very same area. Recognizing the immense potential, the City made the decision to sell the property to Google, enabling the realization of their visionary Downtown West project. Spanning an impressive 7.3 million square feet and with a staggering investment of $19 billion, the Downtown West project promises to transform the Diridon Station Area into a bustling hub of innovation, commerce, and community.Bob Staedler`s astute leadership and strategic decision-making played a crucial role in laying the foundation for this transformative project. While the Athletics` relocation plans did not materialize, the subsequent sale of the property to Google signifies a bright and prosperous future for the Diridon Station Area, ensuring its evolution into a dynamic and vibrant destination at the intersection of technology, transportation, and urban development.',
      titledetailimg1: former,
    },
    {
      img: cityAlameda2,
      title: 'City of Alameda, Real estate consulting for Alameda Naval Air Station Transfer',
      description: '',
      titledetail: 'Consulted for the City of Alameda as they undertook the transfer of the Alameda Naval Air Station.',
      titledetailimg: cityAlameda1,
      titledetail1: 'As Alameda looked to transform this historic site into a vibrant hub of economic growth, environmental sustainability, and community engagement, Bob brought a wealth of expertise in urban planning, public-private partnerships, and stakeholder engagement. With an unwavering commitment to revitalizing underutilized spaces, Bob possesses a deep understanding of the complex dynamics involved in large-scale redevelopment projects.',
      titledetailimg1: cityAlameda2,
    },
    {
      img: plaza2,
      title: 'Plaza de San Jose Redevelopment Project',
      description: '191,000 square feet of retail, $73M',
      titledetail: 'Bob Staedler led the transformation of a historic area of San Jose. The Plaza de San Jose Redevelopment project has seen an incredible metamorphosis under Bob’s strategic guidance. With 191,000 square feet of retail space and a budget of $73 million, Bob has skillfully orchestrated the revitalization of this iconic destination.',
      titledetailimg: plaza1,
      titledetail1: 'From meticulous planning and impeccable project management Bob brought new life and vitality to the heart of the east side of San Jose. Witness the seamless blend of modern design, vibrant commercial spaces, and enhanced community engagement, as Plaza de San Jose emerges as a shining example of urban redevelopment excellence.',
      titledetailimg1: plaza2,
    },
    {
      img: coyote2,
      title: 'Coyote Valley Preservation:',
      description: '937 acres preserved, $93M',
      titledetail: 'Nestled in the heart of South San Jose lies a natural gem of immeasurable value: Coyote Valley and its watershed. As urbanization continues to reshape our cities, the preservation of this vital ecosystem has become a paramount concern for environmentalists, communities, and policymakers alike.Stretching across acres of pristine land, Coyote Valley serves as a critical wildlife corridor, providing refuge for numerous plant and animal species. Its watershed acts as a natural filtration system, replenishing underground aquifers and ensuring a sustainable water supply for the region. However, the delicate balance of this ecosystem is under threat due to encroaching urban development.Recognizing the ecological significance of Coyote Valley, Bob Staedler consulted for the Santa Clara Open Space Authority for its preservation. Bob advocated for the City of San Jose to use Measure T bond proceeds to purchase the Sobrato/Brandenburg holdings.',
      titledetailimg: coyote1,
      titledetail1: 'The preservation of Coyote Valley and its watershed is not only crucial for biodiversity conservation but also essential for mitigating the impacts of climate change. The valley acts as a natural carbon sink, absorbing and storing greenhouse gases, while its wetlands help regulate local temperatures and mitigate flooding risks.By working hand in hand with stakeholders Bob advocates for sustainable land-use policies, smart growth strategies, and the creation of nature reserves and protected areas such as Coyote Valley. Ensuring a resilient future for South San Jose and leaving a lasting legacy of environmental stewardship for generations to come.',
      titledetailimg1: coyote3,
    },
    {
      img: diridon1,
      title: 'Diridon Area Neighborhood Group (DANG):City of Alameda, Real estate consulting for Alameda Naval Air Station Transfer',
      description: 'Represented neighborhoods adjacent to the Diridon Station Area Plan and Google Downtown West',
      titledetail: 'Bob Staedler, a renowned consultant in urban planning and community development, played a pivotal role in fostering collaboration and creating a win-win scenario for the neighborhoods surrounding the transformative Google Downtown West project. With his deep expertise and unwavering commitment to community engagement, Staedler facilitated a constructive dialogue between local residents, and Google, ensuring that the project`s development aligned with the needs and aspirations of the community. Drawing upon his extensive experience in navigating complex urban projects, Staedler provided strategic guidance and invaluable insights to all stakeholders involved. By actively listening to the concerns and aspirations of the neighborhood, he facilitated a shared vision that balanced the goals of economic growth, environmental sustainability, and community well-being.',
      titledetailimg: diridon1,
      titledetail1: 'Staedler`s consulting efforts focused on ensuring that the Google Downtown West project integrated seamlessly into the fabric of the existing neighborhoods, preserving their unique character while driving positive change. Through thoughtful planning and creative solutions, he helped identify opportunities for affordable housing, improved transportation infrastructure, and enhanced public spaces, creating a thriving urban environment that benefits all. By bridging the gap between stakeholders and fostering collaboration, he has not only created a win-win scenario for the neighborhoods surrounding the Google Downtown West project but has also paved the way for sustainable and inclusive urban growth, ensuring a prosperous future for both residents and businesses alike.',
      titledetailimg1: diridon2,
    },
    {
      img: northSan2,
      title: 'North San Pedro Housing Site:',
      description: '5,89 acres, 1,400 units, $600M',
      titledetail: 'Bob Staedler, a leader in urban redevelopment, spearheaded the transformative North San Pedro Redevelopment project with unwavering dedication and expertise. In a remarkable feat, Staedler revitalized a 5.89-acre brownfield site, converting it into a thriving urban oasis. With over 1,400 residential units and a staggering project value exceeding $600 million, the North San Pedro Redevelopment project stands as a testament to Staedler`s vision and strategic prowess.Guided by a deep commitment to sustainable development, Staedler expertly navigated the complexities of brownfield reclamation, transforming a once-neglected area into a vibrant community hub. Through his astute project management and extensive knowledge of regulatory requirements, Staedler seamlessly integrated environmental remediation with a mix of residential types such as for-sale, rental and very low-income affordable.  He wrote and was awarded a $24 million State of California Prop 1C grant.',
      titledetailimg: northSan1,
      titledetail1: 'By leveraging his vast network and fostering strategic collaborations with developers, architects, and local authorities, Staedler ensured the successful realization of this ambitious venture. The North San Pedro Redevelopment project stands as a shining example of Staedler`s ability to navigate challenges, maximize value, and create vibrant communities.Beyond its impressive economic impact, this revitalization effort has breathed new life into the North San Pedro neighborhood, providing much-needed housing options and injecting vitality into the local economy. Staedler`s leadership and forward-thinking approach have not only transformed a neglected brownfield site but have also elevated the quality of life for residents and set a benchmark for future urban redevelopment projects.',
      titledetailimg1: northSan2,
    },
    // {
    //   img: palaceImage,
    //   title: 'Stanford, Science & Engineering Quad Phase I - including the Hewlett & Packard Buildings',
    //   description: '226,000 square feet, 126,000,000'
    // },
  ]

  const serviesDate = [
    {
      serviceImage: ServiceImages,
      title: 'Real Estate Consulting',
      descriptionArray: [
        'Highest and best use analysis',
        'Market positioning of properties',
        'Alternative feasibility uses of property',
        'Permitting coordination',
        'Title coordination',
        'Lease analysis',
        'Due Diligence',
      ]
    },
    {
      serviceImage: RealEstate,
      title: 'Municipal Consulting',
      descriptionArray: [
        'Real Property management, Land acquisiton / dispositions',
        'Compliance with surplus land act',
        'Lease negotiation and monitoring',
        'Monitoring and tracking of all properties in database',
        'Oversee relocation obiligations',
        'Cell tower negotiations',
        'Coordination with title companies on recorded Items on city property',
        'Appraisal coordination',
        'Utility company coordination',
      ]
    },
    {
      serviceImage: FinanceImages,
      title: 'Higher Education consulting',
      descriptionArray: [
        'Work-force housing',
        'Coordination with government agencies in surrounding areas',
        'Creating models for public private partnership opportunities',
        'Analysis of opportunities for satellite classrooms and partnership opportunities with nearby higher education providers',
        'Sublease analysis for both on-campus and off-campus sites',
        'Report creation for new initiatives',
      ]
    }
  ]

  return (
    <>
      <div className="hero-secton">
        <div className="container social-relative">
          <div className="row">
            <div className="col-md-12 hero-content">
              {/* <h6 className="m-0 titleheader">SILICON VALLEY SYNERGY</h6> */}
              <h1 className="m-0">Transforming Vision into Reality</h1>
              <p className="m-0">At Silicon Valley Synergy, we believe in the power of collaboration. Our approach is to work closely with you as partners, understanding your vision and aligning our strategies to achieve your goals. We provide customized solutions that address your specific needs, fostering a synergistic relationship that drives success. Together, we can transform challenges into opportunities and build a brighter future for your business.</p>
              <div className="submit-button get-start">
                <button type="button" onClick={scrollClick}>GET STARTED</button>
              </div>
            </div>
          </div>
          <BannerLink />
        </div>
      </div>

      <div className="about-container">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 about-content">
              <h4 className="m-0">ABOUT US</h4>
              <h6 className="m-0">SILICON VALLEY SYNERGY MOVES PROJECTS FORWARD</h6>
              <p className="m-0">SVS has the ability to take on complex and difficult projects,creating consensus and delivering positive outcomes. SVS works with all of the stakeholders and at the end of the projects they feel like they were part of the process and their lives are improved by the outcomes.</p>
              <div className="submit-button learn-more">
                <button type="button">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12 service-header">
              <h2>CONSULTING SERVICES</h2>
              {/* <h6>“LUCK IS WHAT HAPPENS WHEN PREPARATION MEETS OPPORTUNITY.”</h6> */}
              <p>Silicon Valley Synergy (SVS) prides itself in positioning our clients for the best results possible. The world has seen many exciting funding opportunities come from Silicon Valley, what they haven’t seen is the amount of work it takes to behind the scenes to prepare for that opportunity.</p>
            </div>

            {serviesDate?.map((item, index) => (
              <div className="col-md-4 card-shade p-0" key={index}>
                <div className="spacing-section">
                  <div className="servicecard-image"
                    style={{ backgroundImage: `url(${item.serviceImage})` }}
                  >
                  </div>
                  <div className="card-detals">
                    <h4>{item.title}</h4>
                    <ul className="p-0 m-0">
                      {item?.descriptionArray?.map((itemDesc, index) => (
                        <li key={index}>
                          {itemDesc}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link to={RoutesPaths.SERVICES}>
                    <div className="submit-button card-learnmore">
                      <button type="button">Learn More</button>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="project-conatiner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 projectportfolio-header">
              <h6>A COMMITMENT TO EXCELLENCE</h6>
              <h2>PROJECT PORTFOLIO</h2>
              <p>We believe that delivering the highest level of customer service yields the greatest rewards.A commitment to value has earned a reputation for quality. Silicon Valley Synergy partners with only those who share the same commitment to quality and service.</p>
            </div>
            <div className="col-md-12">
              <Slider ref={slider} {...settings}>
                {sliderCards.map((card, index) => (
                  <Link to={RoutesPaths.CASESTUDIESDETAILS} state={card} key={index}>
                    <div className="slider-section">
                      <div className="slider-img"
                        style={{ backgroundImage: `url(${card.img})` }}
                      >
                        <img src={slidershade} alt="" className="img-fluid slideShade-dash" />
                      </div>
                      <div className="slider-details">
                        <h3 className="m-0">{card.title}</h3>
                        <p className="m-0">{card.description}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
              <div className='arrow-icon slider-arrow'>
                <button onClick={() => slider?.current?.slickPrev()} className='icon' ><i className="fa-solid fa-angle-left"></i></button>
                <button onClick={() => slider?.current?.slickNext()} className='icon'><i className="fa-solid fa-angle-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={sectionRef}>
        <ContactUs />
      </div>
    </>
  );
}
