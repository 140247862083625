import React from 'react';
import MainLogo from '../Media/Images/logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoutesPaths } from '../Router/constant';

export default function Header() {
  const location = useLocation()
  const navigate = useNavigate()
  const pathLink = [
    {
      title: 'HOME',
      link: RoutesPaths.DASHBOARD
    },
    {
      title: 'CONSULTING SERVICES',
      link: RoutesPaths.SERVICES
    },
    {
      title: 'CASE STUDIES',
      link: RoutesPaths.CASESTUDIES
    },
    {
      title: 'BIO',
      link: RoutesPaths.BIO
    },
  ]
  const getHome = () => {
    navigate(RoutesPaths.DASHBOARD, { state: "dashboard" });
  }

  return (
    <>
      <div className='header-secton'>
        <div className='container'>
          <div className='row header-row'>
            <div className='col-md-3 logo-section'>
              <Link to={RoutesPaths.DASHBOARD}>
                <img src={MainLogo} alt=" " />
              </Link>
            </div>
            <div className='col-md-6 router-links'>
              <ul className='p-0 m-0'>
                {pathLink.map((linkpage, index) => (
                  <li key={index}><Link className={location?.pathname?.split("/")[1] === linkpage?.link?.split("/")[1] ? "active" : ""} to={linkpage.link}>{linkpage.title}</Link></li>
                ))}
              </ul>
            </div>
            <div className='col-md-3 media-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12 media-upper-section'>
                    <ul className='p-0 m-0'>
                      <li>
                        <Link className={location.pathname?.split("/")[1] === RoutesPaths.NEWSMEDIA.split("/")[1] ? "mediaActive" : ""} to={RoutesPaths.NEWSMEDIA}>
                          <i className="fa-solid fa-video"></i>
                          MEDIA
                        </Link>
                      </li>
                      <li>
                        <Link className={location.pathname?.split("/")[1] === RoutesPaths.PODCAST.split("/")[1] ? "mediaActive" : ""} to={RoutesPaths.PODCAST}>
                          <i className="fa-solid fa-podcast"></i>
                          PODCAST
                        </Link>
                      </li>
                      <li>
                        <Link className={location.pathname?.split("/")[1] === RoutesPaths.APP.split("/")[1] ? "mediaActive" : ""} to={RoutesPaths.APP}>
                          <i className="fa-solid fa-mobile-button"></i>
                          APP
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-12 submit-button contact-button'>
                    <button onClick={getHome} type="button">Contact Us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
