import React from "react";
import Footerlogo from '../Media/Images/bottom-logo.png';
import Twitter from "../Media/Icons/twitter-colorlogo.svg";
import LinkedIn from "../Media/Icons/linkedIn-color-logo.svg";
import Cellphone from "../Media/Icons/color-cell-phone.svg";
import Mobile from "../Media/Icons/black-mobile.svg";
import Envelope from '../Media/Icons/color-envelope.svg';
// import Location from '../Media/Icons/location.svg';
import { Link } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";


export default function Footer() {
    return (
        <>
            <div className="footer-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 leftFooter-section">
                            <h6 className="m-0">Stay in touch</h6>
                            <div>
                                <a href="https://twitter.com/BobStaedler?s=20" target="_blank">
                                    <img src={Twitter} alt="" />
                                </a>
                                <a href="https://linkedin.com/in/bob-staedler-371b7131" target="_blank">
                                    <img src={LinkedIn} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 footer-logo">
                            <Link to={RoutesPaths.DASHBOARD}>
                                <img src={Footerlogo} alt="" />
                            </Link>
                        </div>
                        <div className="col-md-4 footer-right-section">
                            <ul className="p-0 m-0">
                                <li>
                                    <a href="tel:408-564-8834" target="_blank">
                                        <div>
                                            <img src={Cellphone} alt="" />
                                        </div>
                                        408-564-8834
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:408-234-4029" target="_blank">
                                        <div>
                                            <img src={Mobile} alt="" />
                                        </div>
                                        408-234-4029
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:bob@svsynergy.com" target="_blank">
                                        <div>
                                            <img src={Envelope} alt="" />
                                        </div>
                                        bob@svsynergy.com
                                    </a>
                                </li>
                                <li>
                                    <Link to={RoutesPaths.TERMSCONDITIONS}>
                                        <div>
                                            <i className="fa-solid fa-list"></i>
                                        </div>
                                        Terms and Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to={RoutesPaths.PRIVACYPOLICY}>
                                        <div>
                                            <i className="fa-solid fa-list"></i>
                                        </div>
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}