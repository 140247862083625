import React, { useRef } from "react";
import RealEstateImages from '../../Media/Images/real-estate1.png';
import MunicipalImages from '../../Media/Images/Municipal-Consulting.png';
import EductionImages from '../../Media/Images/higher-education.png';
import OtherServicesImages from '../../Media/Images/other-services.png';
import Tickicon from "../../Media/Icons/tick-icon.svg"
import ContactUs from '../../Components/ContactUs';
import BannerLink from '../../Components/BannerLink';


export default function Services() {
  const sectionRef = useRef(null);

  const scrollClick = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className="servicesHero-secton">
        <div className="container social-relative">
          <div className="row">
            <div className="col-md-12 hero-content services-section">
              {/* <h6 className="m-0">SILICON VALLEY SYNERGY</h6> */}
              <h1 className="m-0">Services</h1>
              <p className="m-0">At Silicon Valley Synergy, delivering exceptional customer service is our top priority. Our commitment to values and quality has earned us a reputation for excellence. We partner only with those who share our passion for providing the highest level of service and quality.</p>
              <div className="submit-button get-start">
              <button type="button" onClick={scrollClick}>GET STARTED</button>
              </div>
            </div>
          </div>
          <BannerLink/>
        </div>
      </div>

      {/* <div className='rules-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 rulesContent-section'>
              <h6>The rules and regulations of getting approvals, being properly licensed or doing business with the government in a timely manner is more important than ever. The time delay in getting approvals can prove to be quite costly or the inability to contract with the government due to a misunderstanding of what is required can limit a company's growth.</h6>
              <h5>Silicon Valley Synergy can make these experiences far less painful and we can facilitate a positive outcome by coordinating with our clients on the front end of the process.</h5>
            </div>
          </div>
        </div>  
      </div> */}

      <div className='real-estateContainer'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 realEstate-left'>
              <h2>Real Estate Consulting</h2>
              <ul className='p-0 m-0'>
                <li><img src={Tickicon} alt=" " />Highest and best use analysis</li>
                <li><img src={Tickicon} alt=" " />Market positioning of properties</li>
                <li><img src={Tickicon} alt=" " />Alternative feasibility uses of property</li>
                <li><img src={Tickicon} alt=" " />Permitting coordination</li>
                <li><img src={Tickicon} alt=" " />Title coordination</li>
                <li><img src={Tickicon} alt=" " />Lease analysis</li>
                <li><img src={Tickicon} alt=" " />Due Diligence</li>
              </ul>
            </div>
            <div className='col-md-6'>
              <img src={RealEstateImages} alt=" " className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className='real-estateContainer municipal-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <img src={MunicipalImages} alt=" " className="img-fluid" />
            </div>
            <div className='col-md-6 realEstate-left'>
              <h2>Municipal Consulting</h2>
              <ul className='p-0 m-0'>
                <li><img src={Tickicon} alt=" " />Real property management, Land acquisition/disposition</li>
                <li><img src={Tickicon} alt=" " />Compliance with surplus land act</li>
                <li><img src={Tickicon} alt=" " />Lease negotiation and monitoring</li>
                <li><img src={Tickicon} alt=" " />Monitoring and tracking of all properties in database</li>
                <li><img src={Tickicon} alt=" " />Oversee relocation obligations</li>
                <li><img src={Tickicon} alt=" " />Cell tower negotiations</li>
                <li><img src={Tickicon} alt=" " />Coordination with title companies on recorded items on City property</li>
                <li><img src={Tickicon} alt=" " />Appraisal coordination</li>
                <li><img src={Tickicon} alt=" " />Utility company coordination</li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className='real-estateContainer'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 realEstate-left'>
              <h2>Higher education consulting</h2>
              <ul className='p-0 m-0'>
                <li><img src={Tickicon} alt=" " />Work-force housing</li>
                <li><img src={Tickicon} alt=" " />Coordination with government agencies in surrounding areas</li>
                <li><img src={Tickicon} alt=" " />Creating models for public private partnership opportunities</li>
                <li><img src={Tickicon} alt=" " />Analysis of opportunities for satellite classrooms and partnership opportunities with nearby higher education providers</li>
                <li><img src={Tickicon} alt=" " />Sublease analysis for both on-campus and off-campus sites</li>
                <li><img src={Tickicon} alt=" " />Report creation for new initiatives</li>
              </ul>
            </div>
            <div className='col-md-6'>
              <img src={EductionImages} alt=" " className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className='real-estateContainer municipal-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <img src={OtherServicesImages} alt=" " className="img-fluid" />
            </div>
            <div className='col-md-6 realEstate-left other-services'>
              <h2>Other services provided:</h2>
              <ul className='p-0 m-0'>
                <li><img src={Tickicon} alt=" " />Public Relations</li>
                <li><img src={Tickicon} alt=" " />Government relations</li>
                <li><img src={Tickicon} alt=" " />Strategic analysis</li>
                <li><img src={Tickicon} alt=" " />Crisis management</li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div ref={sectionRef}>
        <ContactUs />
      </div>

    </>
  )
}
