import React from "react";
import { Link } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";
import Slider from "react-slick";
export default function Services({ title, titleDesc, desc, response, currentId }) {
    const imageUrl = 'https://svsynergy.digital/public/upload/item/'

    const moveIconSlider = React.useRef(null);

    var dataslider = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        centerPadding: '20px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 service-header social-header">
                        <h2>{title}</h2>
                        <h6>{titleDesc}</h6>
                        <p className="social-description">{desc}</p>
                    </div>

                    <Slider ref={moveIconSlider} {...dataslider}>
                        {response?.map((item, index) => (
                            parseInt(currentId) !== parseInt(item.id) && (
                                <div className="col-md-4 card-shade p-0" key={index}>
                                    <div className="spacing-section media-spacing">
                                        <div className="servicecard-image"
                                            style={{ backgroundImage: `url(${imageUrl + item.img})` }}
                                        >
                                        </div>
                                        <div className="card-detals media-detals">
                                            <h4>{item.name}</h4>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: `${item.description}`,
                                                }}></p>
                                            <Link to={RoutesPaths.NEWSMEDIADETAILS.replace(":id", item.id)}>
                                                <div className="submit-button card-learnmore media-learnmore">
                                                    <button type="button">Read More</button>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </Slider>
                </div>
                {
                    response?.length > 3 &&
                    <div className='row'>
                        <div className='col-md-12 arrow-icon'>
                            <div onClick={() => moveIconSlider?.current?.slickPrev()} className='icon' ><i className="fa-solid fa-angle-left"></i></div>
                            <div onClick={() => moveIconSlider?.current?.slickNext()} className='icon'><i className="fa-solid fa-angle-right"></i></div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}