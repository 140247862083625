import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/landing/Dashboard";
import { RoutesPaths } from "./constant";
import Services from "../Pages/services/Services";
import CaseStudies from "../Pages/case-studies/Casestudies"
import CaseStudiesDetails from "../Pages/case-studies/Casestudiesdetails"
import Bio from "../Pages/bio/Bio"
import NewsMedia from "../Pages/media/Newsmedia";
import NewsMediaDetails from "../Pages/media/NewsMediaDetails";
import Error from "../Pages/error/error";
import Podcast from "../Pages/podcast/Podcast";
import TermsConditions from "../Components/TermsConditions";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import App from "../Pages/app/App";

export default function RouteComponent() {
  return (
    <>
      <Routes>
        <Route exact path={RoutesPaths.DASHBOARD} element={<Dashboard />} />
        <Route exact path={RoutesPaths.SERVICES} element={<Services />} />
        <Route exact path={RoutesPaths.CASESTUDIES} element={<CaseStudies />} />
        <Route exact path={RoutesPaths.CASESTUDIESDETAILS} element={<CaseStudiesDetails />} />
        <Route exact path={RoutesPaths.BIO} element={<Bio />} />
        <Route exact path={RoutesPaths.NEWSMEDIA} element={<NewsMedia />} />
        <Route exact path={RoutesPaths.NEWSMEDIADETAILS} element={<NewsMediaDetails />} />
        <Route exact path={RoutesPaths.PODCAST} element={<Podcast />} />
        <Route exact path={RoutesPaths.ERROR} element={<Error />} />
        <Route exact path={RoutesPaths.TERMSCONDITIONS} element={<TermsConditions />} />
        <Route exact path={RoutesPaths.PRIVACYPOLICY} element={<PrivacyPolicy />} />
        <Route exact path={RoutesPaths.APP} element={<App />} />
        <Route path="*" element={<Navigate to={RoutesPaths.ERROR} />} />
      </Routes>
    </>
  );
}
