import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import RouteComponent from "./Router/RouteComponent";
import ScrollToTop from "./scrolltotop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <RouteComponent></RouteComponent>
      <Footer />
    </>
  );
}

export default App;
