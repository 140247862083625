import React from 'react'
import Twitter from "../Media/Icons/white-twitter.svg";
import LinkedIn from "../Media/Icons/white-linked.svg";

export default function BannerLink() {
    return (
        <>
            <div className="banner-socialLink">
                <a href="https://twitter.com/BobStaedler?s=20" target="_blank">
                    <img src={Twitter} alt="" />
                </a>
                <a href="https://linkedin.com/in/bob-staedler-371b7131" target="_blank">
                    <img src={LinkedIn} alt="" />
                </a>
                {/* <div className="linebar"></div> */}
            </div>
        </>
    )
}


