import React, { useEffect, useState } from 'react'
import Services from '../../Components/Services';
import ContactUs from '../../Components/ContactUs';
import axios from "axios";
import BannerLink from '../../Components/BannerLink';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { RoutesPaths } from '../../Router/constant';



export default function Newsmedia() {
    const activeslider = React.useRef(null);

    const [newsDatas, setNewsData] = useState([]);
    const [mediaDatas, setMediaData] = useState([]);
    const url = "https://svsynergy.digital/api/news";
    const urlMedia = "https://svsynergy.digital/api/news?category_name=media";
    const imageUrl = 'https://svsynergy.digital/public/upload/item/'

    useEffect(() => {
        axios.get(url).then((response) => {
            setNewsData(response.data);
        }).catch((error) => {
            console.log("error", error)
        })
    }, [])

    useEffect(() => {
        axios.get(urlMedia).then((response) => {
            setMediaData(response.data);
        }).catch((error) => {
            console.log("error", error)
        })
    }, [])

    var activenews = {
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        speed: 1000,
    }

    return (
        <>
            <div className='media-container'>
                <div className='container social-relative'>
                    <div className='row'>
                        <div className='col-md-12 mediaheader-title'>
                            <h6 className='m-0'>SILICON VALLEY SYNERGY</h6>
                            <h1 className='m-0'>NEWS & MEDIA</h1>
                        </div>
                    </div>
                    <div className='row newsmedia-slider-details'>
                        <Slider ref={activeslider} {...activenews} className='news-silckSlider'>
                            {newsDatas?.data?.news.map((latestNews, index) => (
                                <Link key={index} to={RoutesPaths.NEWSMEDIADETAILS.replace(":id", latestNews.id)}>
                                    <div className='media-details-section'>
                                        <div className='col-md-8 media-images'
                                            style={{ backgroundImage: `url(${imageUrl + latestNews.img})` }}>
                                            {/* <img src={Playicon} alt="" className='img-fluid play-icon' /> */}
                                        </div>
                                        <div className='col-md-4 rightMedia-details-section'>
                                            <h6 className='m-0'>{latestNews.name}</h6>
                                            <p className='m-0' dangerouslySetInnerHTML={{
                                                __html: `${latestNews.description}`,
                                            }}></p>
                                            {/* <div className="submit-button get-start">
                                    <button type="button">Download the App</button>
                                </div> */}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </Slider>
                        {
                            newsDatas?.data?.news.length > 1 &&
                            <div className='arrow-icon media-arrow activeNewsArrrow'>
                                <div onClick={() => activeslider?.current?.slickPrev()} className='icon'><i className="fa-solid fa-angle-left"></i></div>
                                <div onClick={() => activeslider?.current?.slickNext()} className='icon'><i className="fa-solid fa-angle-right"></i></div>
                            </div>
                        }
                    </div>
                    <BannerLink />

                </div>
            </div>

            <div className='news-container'>
                <Services title='NEWS' titleDesc="Check here for all the latest news concerning Silicon Valley development and planning" desc='' response={newsDatas?.data?.news} />
            </div>

            <div className='news-container newsMedia-container'>
                <Services title='MEDIA' titleDesc="Check here for all the latest media concerning Silicon Valley development and planning" desc='' response={mediaDatas?.data?.news} />
            </div>

            <ContactUs />
        </>
    )
}
