import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import axios from "axios";
import { RoutesPaths } from '../Router/constant';
import { Link } from 'react-router-dom';


export default function ContactUs() {

    const [formsubmit, setFormSubmit] = useState("");
    const [isLoading, setLoading] = useState(false);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        setLoading(true)
        const formurl = "https://svsynergy.digital/api/contact"

        axios.post(formurl, data).then((response) => {
            setFormSubmit(response.data.data);
            reset();
            setLoading(false)
        });
    };

    return (
        <>
            <div className="contact-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 contactForm-section">
                            <div>
                                <h3 className="contactUs-Title m-0">CONTACT US</h3>
                                <Form className="submit-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <label>Your Email*</label>
                                        <input type="email" className="input-text"
                                            {...register("email", { required: true })}
                                        ></input>
                                        {errors.email && <p className='text-start validation'> Please Enter the Valid E-mail!</p>}

                                    </div>
                                    <div>
                                        <label>Your Name*</label>
                                        <input type="text" className="input-text"
                                            {...register("name", { required: true })}
                                        ></input>
                                        {errors.name && <p className='text-start validation'> Please Enter the Name!</p>}
                                    </div>
                                    <div>
                                        <label>Your Number*</label>
                                        <input type="number" className="input-text"
                                            {...register("phone", { required: true })}
                                        ></input>
                                        {errors.phone && <p className='text-start validation'> Please Enter the Number!</p>}
                                    </div>
                                    <div>
                                        <label>Subject*</label>
                                        <input type="text" className="input-text"
                                            {...register("subject", { required: true })}
                                        ></input>
                                        {errors.subject && <p className='text-start validation'> Please Enter the Subject!</p>}
                                    </div>
                                    <div>
                                        <p className="m-0">Your Message*</p>
                                        <textarea type="text" className="input-text"
                                            {...register("message", { required: true })}
                                        ></textarea>
                                        {errors.message && <p className='text-start validation'> Please Enter the Message!</p>}
                                    </div>
                                    <div className="checkbox-section">
                                        <div className='innerCheckbox-section'>
                                            <input type="checkbox" id="confirm-message" {...register("confirm", { required: true })}></input>
                                            <label className="m-0" htmlFor="confirm-message">By sending this message, you confirm that you have read and agreed to our
                                                <Link to={RoutesPaths.PRIVACYPOLICY}> Privacy-policy</Link>.</label>
                                        </div>
                                        <div>
                                            {errors.confirm && <p className='text-start validation'> Please read Privacy-policy!</p>}
                                        </div>
                                    </div>


                                    <div className="submit-button submit-btn">
                                        <button type="submit" disabled={isLoading} className='spinner-btn'>
                                            Submit
                                            {isLoading && <span className="spinner-border spinner-border-sm text-light spinner"></span>}&nbsp;
                                        </button>
                                    </div>
                                </Form>
                                <div className="subscribemail-text">{formsubmit}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
