import React, { useEffect, useState } from 'react';
import dowanload from '../../Media/Icons/dowanloadicon.svg';
import axios from "axios";


export default function Podcast() {

    const [podCastData, setPodCastData] = useState([]);
    const [page, setpage] = useState(1);
    const [total, setTotal] = useState(null);
    const posturl = `https://svsynergy.digital/api/podcast`;
    const imageUrl = 'https://svsynergy.digital/public/upload/item/';

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        fetchData()
    }, [page])

    const fetchData = () => {
        axios.get(posturl + '?page=' + page).then((response) => {
            if(page===1){
                setPodCastData(response.data.data.podcast);
                setTotal(response.data.data.total)
            }else{
                setPodCastData(oldArray => [...oldArray, ...response.data.data.podcast]);
            }
            
        }).catch((error) => {
            console.log("Podcast-error", error);
        })
    }

    const handlePage = () => {
        setpage(page + 1)
    }

    return (
        <>
            <div className='media-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mediaheader-title'>
                            <h6 className='m-0'>SILICON VALLEY SYNERGY</h6>
                            <h1 className='m-0'>PODCAST</h1>
                        </div>
                    </div>

                    <div className='row podcast-card'>
                        <div className='col-md-12'>
                            <div className='podcastHeader-title'>
                                <p className='m-0'>Latest PodCast Episodes</p>
                                {/* <button type="btn">View All</button> */}
                            </div>
                        </div>
                        {podCastData?.map((podcast, index) => (
                            <div className='col-md-12 podcast-section' key={index}>
                                <div className='podcast-details-section p-0'>
                                    <div className='podcast_img'
                                        style={{ backgroundImage: `url(${imageUrl + podcast.img})` }}
                                    ></div>
                                    <div className='podcast-details'>
                                        <h6>{podcast.name}</h6>
                                        <p className='m-0' dangerouslySetInnerHTML={{
                                            __html: `${podcast.description}`,
                                        }}></p>
                                        <div className='play-media'>
                                            <audio controls>
                                                <source src={imageUrl + podcast.audio_file} type={"audio/" + podcast.audio_file.split(".")[1]} />
                                                Your browser does not support the audio element.
                                            </audio>
                                            <a href={imageUrl + podcast.audio_file} download>
                                                <img src={dowanload} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {podCastData.length < total && (
                            <div className='col-md-12 show-more-btn'>
                                <button onClick={handlePage} type="btn">Show More Episodes</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='partners-container'>
                <div className='container-fluid custom-container'>
                    <div className='partner-profile'>
                        <div className='row'>
                            <div className='col-md-12 partner-details podcast-episode'>
                                {/* <h5 className='m-0'>Transforming Vision into Reality </h5> */}
                                <h2 className='m-0'>Stay Tuned for New Episodes Every Week!</h2>
                                {/* <h6 className='m-0'>A solution-focused problem solver and strategic partner, advancing projects with purpose</h6> */}
                                <p className='m-0'>Lorem ipsum dolor sit amet, te his agam complectitur, consul democritum pri ad. Id solum nullam appareat sea, ne wisi paulo dolorum quo, pri justo antiopam in. Lorem ipsum dolor sit amet, te his agam complectitur, consul democritum pri ad. Id solum nullam appareat sea, ne wisi paulo dolorum quo, pri justo antiopam in. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
