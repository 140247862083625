import React from 'react';
import ErrorImg from '../../Media/Images/Error404.png'

export default function error() {
    return (
        <>
            <div className='Error-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 Error-section'>
                            <img src={ErrorImg} alt="" />
                            <h1>Page Not Found</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
