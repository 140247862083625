import React, { useEffect, useState } from 'react'
import Services from '../../Components/Services';
import ContactUs from '../../Components/ContactUs';
import axios from "axios";
import { useParams } from 'react-router-dom';
import moment from "moment/moment";

export default function NewsMediaDetails() {

    const { id } = useParams()
    const [newsDetails, setNewsDetails] = useState([]);
    const [moreNews, setmoreNews] = useState([]);
    const posturl = `https://svsynergy.digital/api/news/${id}`;
    const newsurl = `https://svsynergy.digital/api/news`;
    const imageUrl = 'https://svsynergy.digital/public/upload/item/'

    useEffect(() => {
        axios.get(posturl).then((response) => {
            setNewsDetails(response.data.data);
        }).catch((error) => {
            console.log("NewsDetails-error", error)
        })
    }, [id])

    useEffect(() => {
        axios.get(newsurl).then((response) => {
            setmoreNews(response.data);
        }).catch((error) => {
            console.log("error", error)
        })
    }, [id])

    return (
        <>
            <div className='news-container mediaNews-Container'>
                <div className='container'>
                    <div className='row newsMedia-section'>
                        <div className='col-md-12 news-image'
                            style={{ backgroundImage: `url(${imageUrl + newsDetails.img})` }}>
                        </div>
                        <div className='col-md-12 media-newsDetails'>
                            <span>
                                {moment(newsDetails.created_at).format("DD / MM / YYYY")}
                            </span>
                            <h6>
                                {newsDetails.name}
                            </h6>
                            <p dangerouslySetInnerHTML={{
                                __html: `${newsDetails.description}`,
                            }}></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='news-container newsMedia-container'>
                <Services title='MORE NEWS' titleDesc="Lorem ipsum dolor sit amet, te his agam complectitur" desc='Lorem ipsum dolor sit amet, te his agam complectitur, consul democritum pri ad. Id solum nullam appareat sea, ne wisi paulo dolorum quo, pri justo antiopam in' response={moreNews?.data?.news} currentId={id} />
            </div>
            <ContactUs />
        </>
    )
}
