import React from 'react'
import ContactUs from '../../Components/ContactUs'
// import Imgdetails1 from '../../Media/Images/details1.png'
// import Imgdetails2 from '../../Media/Images/details2.png'
import BannerLink from '../../Components/BannerLink'
import { useLocation } from 'react-router-dom'


export default function Casestudiesdetails() {
  const location = useLocation()

  return (
    <>
      <div className='banner-container'
        style={{ backgroundImage: `url(${location.state.img})` }}>
        <div className='shade-section'>
          <div className='container social-relative'>
            <div className='row'>
              <div className='col-md-12 banner-title'>
                <p className='m-0'>{location.state.title}</p>
              </div>
            </div>
            <BannerLink />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row CS-description-detals'>
          <div className='col-md-6 cs-Description'>
            <p className='m-0'>
              {location.state.titledetail}
            </p>
          </div>
          <div className='col-md-6 titleImage'>
            <div style={{ backgroundImage: `url(${location.state.titledetailimg})` }}></div>
          </div>
        </div>
        <div className='row CS-description-detals'>
          <div className='col-md-6 titleImage'>
            <div style={{ backgroundImage: `url(${location.state.titledetailimg1})` }}></div>
          </div>
          <div className='col-md-6 cs-Description'>
            <p className='m-0'>
              {location.state.titledetail1}
            </p>
          </div>
        </div>
      </div>
      <ContactUs />
    </>
  )
}
