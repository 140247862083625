import React from 'react';
import goggleApp from '../../Media/Images/google.png';
import appleApp from '../../Media/Images/applestore.png';
import Logo from '../../Media/Images/SVS_Logo1.png'
import { Link } from 'react-router-dom';
import { RoutesPaths } from '../../Router/constant';

export default function App() {
    const handleClick = () => {
        alert('Coming Soon!');
      };
    return (
        <>
            <div className='news-container newsMedia-container'>
                <div className="container">
                    <div className="row main-section">
                        <div className="col-md-12 text-center container-section">
                            <div className="main-logo">
                                <img
                                    src={Logo}
                                    alt=""
                                />
                            </div>
                            <h6 className="headingText">Get the app now for free</h6>
                            <div className="appShareLogo" onClick={handleClick}>
                                <a href="">
                                    <img src={goggleApp} alt=" " />
                                </a>
                            </div>
                            <div className="appShareLogo" onClick={handleClick}>
                                <a href="">
                                    <img src={appleApp} alt=" " />
                                </a>
                            </div>
                            <div className="shareLink">
                                <div className="leftsharelink">
                                    <Link to={RoutesPaths.PRIVACYPOLICY}>
                                        Terms and Conditions
                                    </Link>
                                </div>
                                <span>|</span>
                                <div className="leftsharelink rightsharelink">
                                    <Link to={RoutesPaths.TERMSCONDITIONS}>
                                        Privacy Policy
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
