import React from 'react';
import ConsultingImages from '../../Media/Images/consulting-services.png';
import SVSClient1Images from '../../Media/Images/SVSclient1.png';
import SVSClient2Images from '../../Media/Images/SVSclient2.png';
import SVSClient3Images from '../../Media/Images/SVSclient3.png';
import Tickicon from "../../Media/Icons/tick-icon.svg"
// import Opencolon from "../../Media/Icons/open-colon.svg"
// import Closecolon from "../../Media/Icons/close-colon.svg"
import ContactUs from '../../Components/ContactUs';
import BannerLink from '../../Components/BannerLink';


export default function Bio() {
  return (
    <>
      <div className="hero-secton Bio-section">
        <div className="container social-relative">
          <div className="row">
            <div className="col-md-12 hero-content Bio-content">
              {/* <h6 className="m-0">SILICON VALLEY SYNERGY</h6> */}
              <h1 className="m-0">BIO</h1>
              <p className="m-0">Bob Staedler is a seasoned professional with 27 years of expertise in economic development, government relations, municipal consulting, and real estate. With a proven track record of success in taking on complex projects and delivering positive outcomes, Bob is known for his ability to bring consensus and resolve even the toughest challenges. Drawing on his extensive experience working in government, university, and private sector environments, Bob has a deep understanding of the need for results in an ever-changing landscape.</p>
            </div>
          </div>
          <BannerLink/>
        </div>
      </div>

      <div className='real-estateContainer bio-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 realEstate-left consulting-services'>
              <h2>SVS assists clients in a variety of consulting services such as:</h2>
              <ul className='p-0 m-0'>
                <li><img src={Tickicon} alt=" " />Long-term planning</li>
                <li><img src={Tickicon} alt=" " />Economic development</li>
                <li><img src={Tickicon} alt=" " />Feasibility Analysis</li>
                <li><img src={Tickicon} alt=" " />Real Estate Advisory</li>
                <li><img src={Tickicon} alt=" " />Due diligence</li>
                <li><img src={Tickicon} alt=" " />Highest and best use analyses</li>
                <li><img src={Tickicon} alt=" " />Cost benefit analysis</li>
                <li><img src={Tickicon} alt=" " />Evaluation of development options</li>
                <li><img src={Tickicon} alt=" " />Real estate transaction structuring</li>
                <li><img src={Tickicon} alt=" " />Consultation on development strategies</li>
                <li><img src={Tickicon} alt=" " />Market positioning on projects and properties</li>
                <li><img src={Tickicon} alt=" " />Feasibility analysis of potential development sites</li>
                <li><img src={Tickicon} alt=" " />Development Agreement analysis</li>
                <li><img src={Tickicon} alt=" " />Title coordination</li>
                <li><img src={Tickicon} alt=" " />Government relations</li>
              </ul>
            </div>
            <div className='col-md-6'>
              <img src={ConsultingImages} alt=" " className="img-fluid" />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-7'>
              <img src={SVSClient1Images} alt=" " className="img-fluid" />
              <img src={SVSClient2Images} alt=" " className="img-fluid" />
              <img src={SVSClient3Images} alt=" " className="img-fluid" />
            </div>
            <div className='col-md-5 realEstate-left consulting-services'>
              <h2>SVS clients include:</h2>
              <ul className='p-0 m-0'>
                <li><img src={Tickicon} alt=" " />City of Redwood City</li>
                <li><img src={Tickicon} alt=" " />City of Alameda</li>
                <li><img src={Tickicon} alt=" " />City of San Jose Successor Agency to the Redevelopment Agency</li>
                <li><img src={Tickicon} alt=" " />City of Half Moon Bay</li>
                <li><img src={Tickicon} alt=" " />City of Santa Rosa</li>
                <li><img src={Tickicon} alt=" " />City of Fremont</li>
                <li><img src={Tickicon} alt=" " />City of Milpitas</li>
                <li><img src={Tickicon} alt=" " />San Jose State University</li>
                <li><img src={Tickicon} alt=" " />San Jose Evergreen Community College District</li>
                <li><img src={Tickicon} alt=" " />San Andreas Regional Center</li>
                <li><img src={Tickicon} alt=" " />Lutheran Social Services of Northern California</li>
                <li><img src={Tickicon} alt=" " />Digital Realty</li>
                <li><img src={Tickicon} alt=" " />Urban Catalyst</li>
                <li><img src={Tickicon} alt=" " />Westcliff Group</li>
                <li><img src={Tickicon} alt=" " />Terrascape Ventures</li>
                <li><img src={Tickicon} alt=" " />Summerhill Homes</li>
                <li><img src={Tickicon} alt=" " />D.R. Horton</li>
                <li><img src={Tickicon} alt=" " />Chandler Pratt</li>
                <li><img src={Tickicon} alt=" " />Court House Plaza Company</li>
                <li><img src={Tickicon} alt=" " />DAL</li>
                <li><img src={Tickicon} alt=" " />Intracorp</li>
                <li><img src={Tickicon} alt=" " />Boccardo Properties</li>
                <li><img src={Tickicon} alt=" " />Forbrich Properties</li>
                <li><img src={Tickicon} alt=" " />Westlake Urban</li>
                <li><img src={Tickicon} alt=" " />Santa Clara County Open Space Authority</li>
                <li><img src={Tickicon} alt=" " />Marcus & Millichap</li>
                <li><img src={Tickicon} alt=" " />Cushman & Wakefield</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='partners-container'>
        <div className='container-fluid custom-container'>
          <div className='partner-profile'>
            <div className='row'>
              <div className='col-md-12 partner-details'>
                <h5 className='m-0'>Transforming Vision into Reality </h5>
                <h2 className='m-0'>Bob Staedler</h2>
                <h6 className='m-0'>A solution-focused problem solver and strategic partner, advancing projects with purpose</h6>
                <p className='m-0'>Bob Staedler is a seasoned professional with 27 years of expertise in economic development, government relations, municipal consulting, and real estate. With a proven track record of success in taking on complex projects and delivering positive outcomes, Bob is known for his ability to bring consensus and resolve even the toughest challenges. Drawing on his extensive experience working in government, university, and private sector environments, Bob has a deep understanding of the need for results in an ever-changing landscape.</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='svComment-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 svComment-content'>
              <p className='m-0'>
                <img src={Opencolon} alt="" className='opencolon' />
                Silicon Valley Synergy is a premier land use and municipal consulting firm, known for delivering successful outcomes on complex projects. With a focus on collaboration and stakeholder engagement, SVS creates consensus and improves lives through its work. The Company's ability to bring all parties together and create solutions that everyone can support is what sets it apart.
                <img src={Closecolon} alt="" className='closecolon' />
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <ContactUs />

    </>
  )
}
