export const RoutesPaths = {
  Home: "/dashboard",
  DASHBOARD: "/",
  SERVICES: "/services",
  CASESTUDIES: "/casestudies",
  CASESTUDIESDETAILS: "/casestudies/details",
  BIO: "/bio",
  MEDIA: "/media",
  NEWSMEDIA: "/news-media",
  PODCAST: "/podcast",
  NEWSMEDIADETAILS: "/news-media/details/:id",
  TERMSCONDITIONS:'/termsandconditions',
  PRIVACYPOLICY:'/PrivacyPolicy',
  APP:'/app',
  ERROR: "/error",
};
